// Bootstrap
@import '~bootstrap/scss/bootstrap.scss';
.form-control-file {
    width: auto;
}
.modal-xl {
    max-width: 95%;
}

// Core theme
@import 'bsdashboard';

// Chosen
@import 'chosen-bootstrap/bs.css';
// match the bootstrap disabled style
.chosen-disabled {
    opacity: 1 !important;
    a, div {
        background-color: $input-disabled-bg !important;
    }
}

// Animate
@import '~animate.css';

// DataTables
@import '~datatables.net-bs4/css/datatables.bootstrap4.css';
//@import '~datatables.net-colreorder-bs4/css/colReorder.bootstrap4.css';
@import '~datatables.net-buttons-bs4/css/buttons.bootstrap4.css';
@import '~datatables.net-responsive-bs4/css/responsive.bootstrap4.css';
@import '~datatables.net-fixedheader-bs4/css/fixedHeader.bootstrap4.css';

table.dataTable {
    border-collapse: collapse !important;
}

// Bootstrap Datepicker
@import '~bootstrap-datepicker/dist/css/bootstrap-datepicker3.css';
.datepicker {
    z-index: 1050 !important;
    table tr {
        td, th {
            padding: 10px;
        }
    }
}

.autocomplete-suggestions { border: 1px solid #999; background: #FFF; overflow: auto; width: auto !important; overscroll-behavior: contain; }
.autocomplete-suggestion { padding: 2px 5px; white-space: nowrap; overflow: hidden; }
.autocomplete-selected { background: #F0F0F0; }
.autocomplete-suggestions strong { font-weight: normal; color: #3399FF; }
.autocomplete-group { padding: 2px 5px; }
.autocomplete-group strong { display: block; border-bottom: 1px solid #000; }

@import 'pace-js';
