.expiring-range {
    width: 3.5rem;
    font-weight: bold;
    line-height: 0px;
    padding: 0px;
    margin: 0px;
}

#dashboard_wrapper {
    .dataTables_wrapper {
        padding: 0px;
        margin: 0px;
    }
    .dataTables_filter {
        text-align: left !important;
    }
}