/*
 * Base structure
 */


/* Move down content because we have a fixed navbar that is 3.5rem tall */
body {
    padding-top: 3.5rem;
}


/*
 * Typography
 */
h1 {
    padding-bottom: 9px;
    margin-bottom: 20px;
    border-bottom: 1px solid #eee;
}

main {
    &.expanded {
        @extend .col;
        margin-left: 5rem;
    }
}

/*
 * Sidebar
 */
.sidebar {
    position: fixed;
    top: 51px;
    bottom: 0;
    left: 0;
    z-index: 80;
    padding: 5px 0;
    background-color: #f7f7f7;
    overflow-x: hidden;
    overflow-y: auto;
    /* Scrollable contents if viewport is shorter than content. */
    border-right: 1px solid #eee;

    &.collapsed {
        width: 5rem !important;

        .nav-item {
            text-align: center;
        }
        
        .nav-item-text, .nav-heading {
            display: none;
        }

        #sidebar_search_form {
            display: none;
        }

        .collapsed_search {
            display: block;
        }
    }


    .nav {
        margin-bottom: 20px;
    }    

    .nav-item {
        width: 100%;
    }

    .nav-heading {
        font-weight: bold;
        padding-left: 1rem;
    }

    .nav-item+.nav-item {
        margin-left: 0;
    }

    .nav-link {
        border-radius: 0;
    }

    #sidebar_search_form {
        margin: 1rem 0.5rem 0.5rem 0.5rem;
        button {
            position: absolute;
            top: 27px;
            right: 15px;
            background: transparent;
            border: 0px;
            color: $text-muted;   
        };
    }

    #sidebar_expand_collapse {
        position: absolute;
        bottom: 0px;
        right: 0px;
    }

    .collapsed_search {
        display: none;
    }
}


/*
 * Dashboard
 */
/* Placeholders */
.placeholders {
    padding-bottom: 3rem;
    
    img {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }
}