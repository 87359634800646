// Vendor
@import 'vendor';

// Variables
// @import 'variables';

@import 'dashboard';

.navbar-brand {
    img {
        position: relative;
        top: -5px;
    }

    margin-right: 0px;
}

#sidebar_expand_collapse {
    display: inline-block;
    color: white;

    a {
        color: white;
    }
}

#flash_message {
    position: fixed;
    top: 70px;
    right: 10px;
    max-width: 600px;
    min-width: 300px;
    z-index: 1040;
    justify-items: center;
}

#flash_message button{
    position: absolute;
    right: 0;
    margin-right: 20px;
    border: 0px;
    background: transparent;
    font-size: 27px;
    line-height: 22px;
}

#error_toast {
    position: fixed;
    top: 70px;
    right: 10px;
    max-width: 600px;
    min-width: 300px;
    z-index: 1040;
    justify-items: center;
}

#error_toast button{
    position: absolute;
    right: 0;
    margin-right: 20px;
    border: 0px;
    background: transparent;
    font-size: 27px;
    line-height: 22px;
}


input[type="number"] {
    text-align: right;
}

.huge {
    font-size: 2.5rem;
}

// .card {
//     height: 100%;
// }

#lease_comments {
    .card-body {
        min-height: 150px;
    }
    .card-body, .form-group, textarea {
        height: 100%;
    }
}

#lease_notes_table_wrapper {
    padding: 0px !important;
}

#unlock_vehicle_make_model {
    cursor: pointer;
}

.datepicker {
    z-index: 1065 !important;
}

input:read-only{
    background-color: #e9ecef;
    opacity: 1;
}

.select-read-only{
    background-color: #e9ecef;
    pointer-events:none;
    touch-action:none;
    opacity: 1;
}

.invalid-feedback:not(.d-none,.d-flex,.invisible,.hide-feedback) { display:block; }
